import operators from './chatBot/operators';

const loadJS = (src) => {
  var script = document.createElement('script');
  script.src = src;

  document.head.appendChild(script);
}

const loadCss = (src) => {
  var script = document.createElement('link');
  script.rel = "stylesheet";
  script.href = src;
  document.head.appendChild(script);
}

const loadFormContent = (type) => {
  if(process.env.NODE_ENV === 'production')
  {
    switch(type) {
    case 'chatbot':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatBot.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatBot.js');
        break;
    case 'chatbotSms':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatBotSms.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatBotSms.js');
        break;
    case 'chatbotSmsDob':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatBotSmsDob.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatBotSmsDob.js');
      break;
    case 'chatbotLeadsta':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatBotSms.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatBotSms.js');
        break;
    case 'chatBotV290day':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatBotV290day.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatBotV290day.js');
        break;
    case 'chatbotv2sf90dayspecialDynamicLogin':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatbotv2sf90dayspecialDynamicLogin.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatbotv2sf90dayspecialDynamicLogin.js');
        break;
    case 'personalLoanNoPDL':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/personalLoanNoPDL.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/personalLoanNoPDL.js');
        break;
    case 'personalLoanPDL':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/personalLoanPDL.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/personalLoanPDL.js');
        break;
    case 'chatbotv2sf90dayspecialDynamicLoginZip':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatbotv2sf90dayspecialDynamicLoginZip.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatbotv2sf90dayspecialDynamicLoginZip.js');
        break;
    case 'chatbotv2sf90dayspecialDynamicLoginDob':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatbotv2sf90dayspecialDynamicLoginDob.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatbotv2sf90dayspecialDynamicLoginDob.js');
        break;
    case 'chatbotv2sf90dayspecialDynamicLoginEmailZip':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatbotv2sf90dayspecialDynamicLoginEmailZip.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatbotv2sf90dayspecialDynamicLoginEmailZip.js');
      break;
    case 'chatbotv2sf90dayspecialDynamicLoginPhoneZip':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatbotv2sf90dayspecialDynamicLoginPhoneZip.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatbotv2sf90dayspecialDynamicLoginPhoneZip.js');
      break;
    case 'chatbotv2GreenCircles':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatbotv2GreenCircles.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatbotv2GreenCircles.js');
        break;
    case 'robotWithHero':
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/robotWithHero.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/robotWithHero.js');
        break;
    default:
      loadCss(process.env.VUE_APP_EMBEDDED_CDN_URL+'/css/chatBotV2.css');
      loadJS( process.env.VUE_APP_EMBEDDED_CDN_URL+'/js/chatBotV2.js');
    }
  } else {
    switch(type) {
    case 'chatbot':
      loadJS( '/js/chatBot.js');
      break;
    case 'chatbotSms':
      loadJS( '/js/chatBotSms.js');
      break;
    case 'chatbotSmsDob':
      loadJS( '/js/chatBotSmsDob.js');
      break;
    case 'chatbotLeadsta':
      loadJS( '/js/chatBotSms.js');
      break;
    case 'chatBotV290day':
      loadJS( '/js/chatBotV290day.js');
      break;
    case 'chatbotv2sf90dayspecialDynamicLogin':
      loadJS( '/js/chatbotv2sf90dayspecialDynamicLogin.js');
      break;
    case 'personalLoanNoPDL':
      loadJS( '/js/personalLoanNoPDL.js');
      break;
    case 'personalLoanPDL':
      loadJS( '/js/personalLoanPDL.js');
      break;
    case 'chatbotv2sf90dayspecialDynamicLoginZip':
      loadJS( '/js/chatbotv2sf90dayspecialDynamicLoginZip.js');
      break;
    case 'chatbotv2sf90dayspecialDynamicLoginPhoneZip':
      loadJS( '/js/chatbotv2sf90dayspecialDynamicLoginPhoneZip.js');
      break;
    case 'chatbotv2sf90dayspecialDynamicLoginEmailZip':
      loadJS( '/js/chatbotv2sf90dayspecialDynamicLoginEmailZip.js');
      break;
    case 'chatbotv2sf90dayspecialDynamicLoginDob':
      loadJS( '/js/chatbotv2sf90dayspecialDynamicLoginDob.js');
      break;
    case 'chatbotv2GreenCircles':
      loadJS( '/js/chatbotv2GreenCircles.js');
      break;
    case 'robotWithHero':
      loadJS( '/js/robotWithHero.js');
      break;
    default:
      loadJS( '/js/chatBotV2.js');
    }
  }
}

const loanAmounts = {
  IL: [{"value":500,"min":100,"max":500,"range":"100;500","label":"$100 - $500","isRepost":false},{"value":800,"min":501,"max":1000,"range":"500;1000","label":"$500 - $1000","isRepost":true},{"value":1000,"min":1001,"max":1500,"range":"1000;1500","label":"$1000 - $1500","isRepost":false},{"value":2000,"min":1501,"max":2000,"range":"1500;2000","label":"$1500 - $2000","isRepost":false},{"value":3000,"min":2001,"max":3000,"range":"2000;3000","label":"$2000 - $3000","isRepost":false},{"value":4000,"min":3001,"max":4000,"range":"3000;4000","label":"$3000 - $4000","isRepost":false},{"value":5000,"min":4001,"max":5000,"range":"4000;5000","label":"$4000 - $5000","isRepost":false}],
  ILS: [{"value":2000,"min":1000,"max":2000,"range":"100;5000","label":"$1000 - $2000","isRepost":false},{"value":3000,"min":2001,"max":3000,"range":"100;5000","label":"$2000 - $3000","isRepost":false},{"value":4000,"min":3001,"max":4000,"range":"100;5000","label":"$3000 - $4000","isRepost":false},{"value":5000,"min":4001,"max":5000,"range":"100;5000","label":"$4000 - $5000","isRepost":false}],
  ILS100: [{"value":1000,"min":100,"max":1000,"range":"100;5000","label":"$100 - $1000","isRepost":false},{"value":2000,"min":1000,"max":2000,"range":"100;5000","label":"$1000 - $2000","isRepost":false},{"value":3000,"min":2001,"max":3000,"range":"100;5000","label":"$2000 - $3000","isRepost":false},{"value":4000,"min":3001,"max":4000,"range":"100;5000","label":"$3000 - $4000","isRepost":false},{"value":5000,"min":4001,"max":5000,"range":"100;5000","label":"$4000 - $5000","isRepost":false}],
  PDL: [{"value":250,"min":100,"max":250,"range":"100;250","label":"$100 - $250","isRepost":false},{"value":500,"min":251,"max":500,"range":"250;500","label":"$250 - $500","isRepost":false},{"value":750,"min":501,"max":750,"range":"500;750","label":"$500 - $750","isRepost":false},{"value":1000,"min":751,"max":1000,"range":"750;1000","label":"$750 - $1000","isRepost":false}],
  RLR: [{"value":1000,"min":100,"max":1000,"range":"100;1000","label":"$100 - $1000","isRepost":true},{"value":2500,"min":1001,"max":2500,"range":"1000;2500","label":"$1000 - $2500","isRepost":false},{"value":5000,"min":2501,"max":5000,"range":"2500;5000","label":"$2500 - $5000","isRepost":false}],
  PRL: [{"value":500,"min":100,"max":500,"range":"100;500","label":"$100 - $500","isRepost":false},{"value":1000,"min":501,"max":1000,"range":"500;1000","label":"$500 - $1000","isRepost":true},{"value":2500,"min":1001,"max":2500,"range":"1000;2500","label":"$1000 - $2500","isRepost":false},{"value":5000,"min":2501,"max":5000,"range":"2500;5000","label":"$2500 - $5000","isRepost":false},{"value":10000,"min":5001,"max":10000,"range":"5000;10000","label":"$5000 - $10000","isRepost":false},{"value":15000,"min":10001,"max":15000,"range":"10000;15000","label":"$10000 - $15000","isRepost":false},{"value":20000,"min":15001,"max":20000,"range":"15000;20000","label":"$15000 - $20000","isRepost":false},{"value":25000,"min":20001,"max":25000,"range":"20000;25000","label":"$20000 - $25000","isRepost":false},{"value":30000,"min":25001,"max":30000,"range":"25000;30000","label":"$25000 - $30000","isRepost":false}],
}

function getLegalUrlsObject(config){
  const baseUrl= config?.legalUrls?.base;
  if(config.legalUrls?.base) {
    return {
      adDisclosure: `${baseUrl}/advertising-disclosure/text`,
      privacyPolicy: `${baseUrl}/privacy-policy/text`,
      tos:`${baseUrl}/terms-and-conditions/text`,
      disclaimer:`${baseUrl}/disclaimer/text`,
      eConsent:`${baseUrl}/e-consent/text`,
    }
  }

  return {
    adDisclosure: config?.legalUrls?.adDisclosure || null,
    privacyPolicy:config?.legalUrls?.privacyPolicy || null,
    tos:config?.legalUrls?.tos || null,
    disclaimer:config?.legalUrls?.disclaimer || null,
    eConsent:config?.legalUrls?.eConsent || null,
  }
}

function getLoanAmounts(config) {
  if (config.loanAmounts && Array.isArray(config.loanAmounts)) {
    //validate LoanAmount
    //{"value":500,"min":100,"max":500,"range":"100;500","label":"$100 - $500","isRepost":false},
    const la = config.loanAmounts.map(i => (
      {
        value: parseInt(i.value),
        min: parseInt(i.min),
        max: parseInt(i.max),
        range: i.range,
        label: i.label,
        isRepost: +i.isRepost
      }
    ))
    const isNotValidLoanAmounts = la.some((i) =>
      (!i.value || !i.min || !i.max || !i.range || !i.label || i.isRepost==null)
    );

    if (!isNotValidLoanAmounts) {
      return config.loanAmounts;
    }
  }

  const loanAmountType = config.loanAmountType || "IL";
  return loanAmounts[loanAmountType];
}

window.EmbeddedForm= {
  init (config) {
    window.EmbeddedForm.config = {
      webmasterID: config.webmasterId || config.aid,
      webmasterID2: config.webmasterId2 || config.webmasterId ||config.aid,
      subaccount: config.subaccount || config.subacc,
      applyElementSelector: config.applyElementSelector || '#jsform',
      scrollOffsetY: config.scrollOffset || 20,
      welcomeText: config.welcomeText || '',
      welcomeTextShortForm: config.welcomeTextShortForm || '',
      s1: config.s1,
      s2: config.s2,
      s3: config.s3,
      s4: config.s4,
      html5HistoryMode: config.html5HistoryMode,
      formStartUrl: config.formStartUrl,
      showLoanAmountOnLogin: config.showLoanAmountOnLogin || true,
      legalUrls: getLegalUrlsObject(config),
      lead: config.lead,
      useColorSchema: config.useColorSchema || null,
    };

    window.EmbeddedForm.config.brand = {
      name_llc: config.llcName || window.location.host,
      name: config.llcName || window.location.host,
      address: config.address || '',
      email: config.email || '',
      unsubscribe_email: config.unsubscribeEmail || 'unsubscribe@WestBrandMarketing.com',
      compliance_email: config.complianceEmail || 'compliance@WestBrandMarketing.com',
    };

    window.EmbeddedForm.config.loanAmouts = getLoanAmounts(config);
    window.EmbeddedForm.apiUrl = process.env.VUE_APP_EMBEDDED_FORM_API_URL;

    const operator = config.operator || 'woman_3';
    window.EmbeddedForm.config.website = {
      operator: operators[operator] ||  operators['woman_3'],
    }

    loadFormContent(config.formType || 'chatbot')
  },
  addEventHandlers (event, handler) {
    if(!window.EmbeddedForm.events) {
      window.EmbeddedForm.events = {}
    }

    window.EmbeddedForm.events[event] = handler
  }
}
